var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"automation-form tlw-h-full tlw-flex tlw-flex-col"},[_c('header',{staticClass:"automation-form__header tlw-pl-6 tlw-mb-4"},[(_vm.filtrable)?_c('span',{staticClass:"number"},[_vm._v("2")]):_vm._e(),_vm._v(" "),_c('div',[_c('strong',{staticClass:"tlw-text-base"},[_vm._v(_vm._s(_vm.$t('automations.edit.form.sendgrid.subtitle')))]),_vm._v(" "),_c('p',{staticClass:"tlw-text-gray-700 tlw-mb-0",domProps:{"innerHTML":_vm._s(_vm.$t('automations.edit.form.sendgrid.desc'))}})])]),_vm._v(" "),_c('div',{staticClass:"tlw-pl-6"},[_c('div',{staticClass:"subject tlw-pb-2 tlw-border-b"},[_c('hs-group',{attrs:{"label":_vm.$t('automations.edit.form.sendgrid.email-subject.label'),"label-for":"subject"}},[_c('hs-input',{attrs:{"id":"subject","placeholder":_vm.$t('automations.edit.form.sendgrid.email-subject.placeholder'),"state":_vm.$v.form.subject.$error ? false : null},on:{"blur":function($event){return _vm.$v.form.subject.$touch()}},model:{value:(_vm.form.subject),callback:function ($$v) {_vm.$set(_vm.form, "subject", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.subject"}}),_vm._v(" "),_c('hs-form-invalid-feedback',{attrs:{"state":!_vm.$v.form.subject.$error}},[_vm._v("\n          "+_vm._s(_vm.$t('validations.required'))+"\n        ")])],1)],1),_vm._v(" "),_c('div',{staticClass:"email tlw-flex-1 tlw-flex tlw-flex-col tlw-pt-4",class:_vm.$v.form.body.$error && 'invalid'},[_c('label',{attrs:{"for":"email-text"}},[_vm._v(_vm._s(_vm.$t('automations.edit.form.sendgrid.email-text')))]),_vm._v(" "),_c('Editor',{staticClass:"tlw-flex-1",attrs:{"initial-value":_vm.form.body,"init":Object.assign({}, _vm.editorDefaultInit,
          {plugins: "lists code preview fullscreen image advlist \n                    charmap visualblocks autolink link table",
          toolbar: "bold italic underline strikethrough | variables | clearBody | \n                    formatselect | forecolor backcolor removeformat | alignleft aligncenter alignright alignjustify |\n                    bullist numlist outdent indent | link image table |\n                    preview fullscreen code | undo redo",
          setup: function(editor) {
            var toggleState = false;
            editor.ui.registry.addMenuButton('variables', {
              text: _vm.$t('automations.edit.form.custom-fields.title-btn'),
              fetch: function(callback) {
                var items = _vm.getCustomFields(editor);
                callback(items);
              },
            });
            editor.ui.registry.addButton('clearBody', {
              icon: 'remove',
              text: 'Limpar',
              onAction: function (_) {
                _vm.form.body = '';
              },
            });
            editor.on('blur', function () {
              _vm.$v.form.body.$touch();
            });
          }})},model:{value:(_vm.form.body),callback:function ($$v) {_vm.$set(_vm.form, "body", $$v)},expression:"form.body"}}),_vm._v(" "),(_vm.$v.form.body.$error)?_c('div',{staticClass:"invalid-feedback d-block"},[(!_vm.$v.form.body.required)?_c('span',[_vm._v("\n          "+_vm._s(_vm.$t('validations.required'))+"\n        ")]):_vm._e()]):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }