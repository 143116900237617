


























































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { required, url } from 'vuelidate/lib/validators';
import { Automation, AutomationSettings, WebhookSettings } from '@/types/automation';
import liquidVariables, { TriggerType } from '@/data/liquid_variables_by_triggers';
import { defaultBody, recurrencyBody, abandonedCartBody } from '@/data/webhook_default_body';
import { SubscriptionTriggers } from '@/types/automation';
import ToastHelper from '@/shared/helpers/toast';

import _ from 'lodash';
import HsForm from '@/components/Form.vue';
import LiquidVariablesCopier from '@/components/LiquidVariablesCopier.vue';
import MButton from '@/shared/components/MButton.vue';
import CodeEditor from 'simple-code-editor';
import { namespace } from 'vuex-class';
import TrackingHelper from '@/shared/helpers/tracking';
import eventService from '@/services/event';
import debounce from 'lodash.debounce';
import MInput from '@/components/MInput.vue';

type Method = {
  text: string;
  value: string;
};

const SchoolModule = namespace('school');
const AuthModule = namespace('auth');

@Component({
  components: {
    HsForm,
    MButton,
    CodeEditor,
    LiquidVariablesCopier,
    MInput,
  },

  validations: {
    form: {
      url: {
        required,
        url,
      },
      headers: {
        $each: {
          key: {
            required,
          },
          value: {
            required,
          },
        },
      },
    },
  },
})
export default class AutomationsEditFormWebhook extends Vue {
  @Prop({ required: true }) automation: Automation;
  @Prop({ required: true }) filtrable: boolean;
  @SchoolModule.State selectedSchool!: any;
  @AuthModule.State loggedUser!: any;

  form = {} as WebhookSettings;

  methods: Method[] = [
    { text: 'POST', value: 'post' },
    { text: 'GET', value: 'get' },
    { text: 'PUT', value: 'put' },
    { text: 'DELETE', value: 'delete' },
  ];

  defaultVariables: object = {};
  search: string = '';
  searchVariables: object = {};
  hasSavedURL: boolean = false;
  defaultFaqUrl: string =
    'https://ajuda.herospark.com/hc/pt-br/articles/8453293384077-Vari%C3%A1veis-Dispon%C3%ADveis-Gatilhos-de-Pagamento-e-Engajamento';
  recurrencyFaqUrl: string =
    'https://ajuda.herospark.com/hc/pt-br/articles/28110850613901-Vari%C3%A1veis-Dispon%C3%ADveis-Gatilhos-de-Recorr%C3%AAncia';

  get faqUrl() {
    return this.recurrency ? this.recurrencyFaqUrl : this.defaultFaqUrl;
  }

  get configurations() {
    return this.automation.configurations || this.automation.settings || ({} as AutomationSettings);
  }

  get recurrency() {
    return this.automation.category === 'Recorrências';
  }

  created() {
    const { url, request_method, headers, request_headers, body } = this.configurations;
    this.hasSavedURL = !!url;
    this.form = {
      url: url || '',
      request_method: request_method || 'post',
      headers: this.checkHeaders(headers),
      request_headers: request_headers || {},
      body: this.bodyConciliation(body),
    };
    this.onSearch = debounce(this.onSearch, 300);
    this.defaultVariables = { ...liquidVariables[this.automation.event.trigger] };
    this.searchVariables = this.defaultVariables;
    this.$root.$on('updated-automation', () => {
      this.hasSavedURL = true;
    });
  }

  checkHeaders(headers: any[] | undefined) {
    if (headers) return headers;
    return this.recurrency ? [{ key: 'Content-Type', value: 'application/json' }] : [];
  }

  async testAutomation() {
    try {
      await eventService.testAutomation({
        school_id: this.selectedSchool.id,
        user_id: this.loggedUser.id,
        action_id: this.automation.id,
        trigger: this.automation.event.trigger,
        variables: this.form.body,
      });
      ToastHelper.successMessage(this.$t('automations.edit.form.webhook.automation-test.toast.success'));
    } catch (error) {
      ToastHelper.dangerMessage(this.$t('automations.edit.form.webhook.automation-test.toast.error'));
    }
  }

  validateTriggerType(): string {
    if (this.recurrency) {
      return SubscriptionTriggers[this.automation.event.trigger.toUpperCase()] ? recurrencyBody : defaultBody;
    } else {
      if (this.automation.event.trigger === 'abandoned_cart') {
        return abandonedCartBody;
      }
      return liquidVariables[this.automation.event.trigger.toUpperCase()];
    }
  }

  bodyConciliation(bodyRaw: object | null | string | undefined): string {
    if (_.isObject(bodyRaw)) return JSON.stringify(bodyRaw, null, '\t');
    if (!bodyRaw) return this.validateTriggerType();

    try {
      let body = bodyRaw.replaceAll("'", '"').replaceAll('=>', ':');
      return JSON.stringify(JSON.parse(body), null, '\t');
    } catch (error) {
      return bodyRaw;
    }
  }

  get headerIsEmpty(): boolean {
    return this.form.headers.some(header => !header.key || !header.value);
  }

  @Watch('form', { deep: true })
  onChangeValue(value) {
    this.$emit('input', { form: value, invalid: this.$v.form.$invalid });
  }

  addNewHeaderItem() {
    this.form.headers.push({
      key: '',
      value: '',
    });
    TrackingHelper.trackAutomationAddHeader(this.selectedSchool.id, this.automation.id);
  }

  removeHeader(index) {
    this.$delete(this.form.headers, index);
    this.transformRequestHeaders();
  }

  transformRequestHeaders() {
    const res = this.form.headers.reduce(function(m, v) {
      m[v.key] = v.value;
      return m;
    }, {});

    this.$set(this.form, 'request_headers', res);
  }

  trackOptionMethods(option) {
    TrackingHelper.trackAutomationMethodUsed(option, this.automation.id);
  }

  redirectToFaq() {
    window.open(this.faqUrl, '_blank');
  }

  @Watch('search')
  onSearch(value: string) {
    if (value) {
      const wordToSearch = this.normalizeString(value);
      this.searchVariables = {};
      Object.entries(this.defaultVariables).forEach(([key, value]) => {
        const normalizedValue = this.normalizeString(value);
        if (normalizedValue.match(wordToSearch) || key.match(wordToSearch)) this.searchVariables[key] = value;
      });
    } else {
      this.searchVariables = this.defaultVariables;
    }
  }

  normalizeString(value: string) {
    return value
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/-/g, '');
  }
}
