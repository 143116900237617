









































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { required } from 'vuelidate/lib/validators';
import { Automation } from '@/types/automation';
import { CustomFieldsByTrigger } from '@/libs/automation';
import TrackingHelper from '@/shared/helpers/tracking';
import MButton from '@/shared/components/MButton.vue';
import Editor from '@tinymce/tinymce-vue';
import { editorDefaultInit } from '@/libs/tinymce';

@Component({
  components: {
    MButton,
    Editor,
  },
  validations: {
    form: {
      subject: {
        required,
      },
      body: {
        required,
      },
    },
  },
})
export default class AutomationsEditFormSendgrid extends Vue {
  @Prop({ required: true }) automation: Automation;
  @Prop({ required: true }) filtrable: boolean;

  form: any = {};
  editorDefaultInit = editorDefaultInit;

  created() {
    const { subject, body } = this.automation.settings || {};
    this.form = {
      subject: subject || '',
      body: body || '',
      scope: 'User',
    };
  }

  @Watch('form', { deep: true })
  onChangeValue(value) {
    this.$emit('input', { form: value, invalid: this.$v.form.$invalid });
  }

  getCustomFields(editor: any) {
    const customFields = CustomFieldsByTrigger(this.automation.event.trigger);

    return customFields.map(cf => ({
      type: 'menuitem',
      text: this.$t(`automations.edit.form.custom-fields.fields.${cf.id}`),
      onAction: function() {
        TrackingHelper.trackAutomationUseVariable(cf.field, 'email', cf.id);
        editor.insertContent(`&nbsp;<span style='color: #7427f1'>{{${cf.field}}}</span>&nbsp;`);
      },
    }));
  }
}
