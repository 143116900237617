import i18n from '@/shared/helpers/i18n';
import Features from '@/types/features';
import { AutomationModel } from '@/types/automation';

const actionDefault = {
  active: false,
  conditions: {},
  integration: 'Sendgrid',
  scope: 'User',
  settings: {},
  configurations: null,
};

const webhookAction = {
  active: false,
  conditions: {},
  integration: 'Webhook',
  scope: 'Integration',
  settings: null,
  configurations: {},
};

export const progressAutomationsTriggers: any[] = [
  'progress_in_25',
  'progress_in_50',
  'progress_in_75',
  'progress_in_100',
];

export const AutomationModels: AutomationModel[] = [
  {
    title: i18n.$t('automations.models.payment-boleto-created.title'),
    desc: i18n.$t('automations.models.payment-boleto-created.desc'),
    category: 'cart_recovery',
    trigger: 'spark_payment_boleto_created',
    checkout: [Features.SPARK_CHECKOUT],
    filter_compare: 'equals',
    filter_key: 'product_id',
    action: actionDefault,
  },
  {
    title: i18n.$t('automations.models.payment-boleto-overdue.title'),
    desc: i18n.$t('automations.models.payment-boleto-overdue.desc'),
    category: 'cart_recovery',
    trigger: 'payment_boleto_overdue',
    checkout: [Features.EXPRESS_CHECKOUT],
    filter_compare: 'equals',
    filter_key: 'product_id',
    action: actionDefault,
  },
  {
    title: i18n.$t('automations.models.new-order.title'),
    desc: i18n.$t('automations.models.new-order.desc'),
    category: 'upsell',
    trigger: 'new_order',
    checkout: [Features.EXPRESS_CHECKOUT],
    filter_compare: 'include',
    filter_key: 'products_ids',
    action: actionDefault,
  },
  {
    title: i18n.$t('automations.models.payment-boleto-overdue.title'),
    desc: i18n.$t('automations.models.payment-boleto-overdue.desc'),
    category: 'cart_recovery',
    trigger: 'payment_boleto_overdue',
    checkout: [Features.SPARK_CHECKOUT],
    filter_compare: 'equals',
    filter_key: 'product_id',
    action: actionDefault,
  },

  {
    title: i18n.$t('automations.models.chargeback-request.title'),
    desc: i18n.$t('automations.models.chargeback-request.desc'),
    category: 'cart_recovery',
    trigger: 'chargeback_request',
    checkout: [Features.SPARK_CHECKOUT],
    filter_compare: 'equals',
    filter_key: 'product_id',
    action: actionDefault,
  },
  {
    title: i18n.$t('automations.models.refunded.title'),
    desc: i18n.$t('automations.models.refunded.desc'),
    category: 'cart_recovery',
    trigger: 'refunded',
    checkout: [Features.SPARK_CHECKOUT],
    filter_compare: 'equals',
    filter_key: 'product_id',
    action: actionDefault,
  },
  {
    title: i18n.$t('automations.models.payment-confirmed.title'),
    desc: i18n.$t('automations.models.payment-confirmed.desc'),
    category: 'cart_recovery',
    trigger: 'spark_payment_confirmed',
    checkout: [Features.SPARK_CHECKOUT],
    filter_compare: 'equals',
    filter_key: 'product_id',
    action: actionDefault,
  },
  {
    title: i18n.$t('automations.models.payment-confirmed-order-bump.title'),
    desc: i18n.$t('automations.models.payment-confirmed-order-bump.desc'),
    category: 'cart_recovery',
    trigger: 'spark_payment_confirmed_order_bump',
    checkout: [Features.SPARK_CHECKOUT],
    filter_compare: 'equals',
    filter_key: 'product_id',
    action: actionDefault,
  },
  {
    title: i18n.$t('automations.models.created-pix.title'),
    desc: i18n.$t('automations.models.created-pix.desc'),
    category: 'cart_recovery',
    trigger: 'payment_pix_created',
    checkout: [Features.SPARK_CHECKOUT],
    filter_compare: 'equals',
    filter_key: 'product_id',
    action: actionDefault,
  },
  {
    title: i18n.$t('automations.models.expired-pix.title'),
    desc: i18n.$t('automations.models.expired-pix.desc'),
    category: 'cart_recovery',
    trigger: 'payment_pix_overdue',
    checkout: [Features.SPARK_CHECKOUT],
    filter_compare: 'equals',
    filter_key: 'product_id',
    action: actionDefault,
  },
  {
    title: i18n.$t('automations.models.subscription_deactivate.title'),
    desc: i18n.$t('automations.models.subscription_deactivate.desc'),
    category: 'recurrency',
    trigger: 'subscription_deactivate',
    checkout: [Features.SPARK_CHECKOUT],
    filter_compare: 'equals',
    filter_key: 'product_id',
    action: actionDefault,
  },
  {
    title: i18n.$t('automations.models.subscription_activate.title'),
    desc: i18n.$t('automations.models.subscription_activate.desc'),
    category: 'recurrency',
    trigger: 'subscription_activate',
    checkout: [Features.SPARK_CHECKOUT],
    filter_compare: 'equals',
    filter_key: 'product_id',
    action: actionDefault,
  },
  {
    title: i18n.$t('automations.models.subscription_cancel.title'),
    desc: i18n.$t('automations.models.subscription_cancel.desc'),
    category: 'recurrency',
    trigger: 'subscription_cancel',
    checkout: [Features.SPARK_CHECKOUT],
    filter_compare: 'equals',
    filter_key: 'product_id',
    action: actionDefault,
  },
  {
    title: i18n.$t('automations.models.subscription_recover.title'),
    desc: i18n.$t('automations.models.subscription_recover.desc'),
    category: 'recurrency',
    trigger: 'subscription_recover',
    checkout: [Features.SPARK_CHECKOUT],
    filter_compare: 'equals',
    filter_key: 'product_id',
    action: actionDefault,
  },
  {
    title: i18n.$t('automations.models.subscription_update.title'),
    desc: i18n.$t('automations.models.subscription_update.desc'),
    category: 'recurrency',
    trigger: 'subscription_update',
    checkout: [Features.SPARK_CHECKOUT],
    filter_compare: 'equals',
    filter_key: 'product_id',
    action: actionDefault,
  },
  {
    title: i18n.$t('automations.models.subscription_mark_past_due.title'),
    desc: i18n.$t('automations.models.subscription_mark_past_due.desc'),
    category: 'recurrency',
    trigger: 'subscription_mark_past_due',
    checkout: [Features.SPARK_CHECKOUT],
    filter_compare: 'equals',
    filter_key: 'product_id',
    action: actionDefault,
  },
  {
    title: i18n.$t('automations.models.subscription_end.title'),
    desc: i18n.$t('automations.models.subscription_end.desc'),
    category: 'recurrency',
    trigger: 'subscription_end',
    checkout: [Features.SPARK_CHECKOUT],
    filter_compare: 'equals',
    filter_key: 'product_id',
    action: actionDefault,
  },
  {
    title: i18n.$t('automations.models.installment_deactivate.title'),
    desc: i18n.$t('automations.models.installment_deactivate.desc'),
    category: 'recurrency',
    trigger: 'installment_deactivate',
    checkout: [Features.SPARK_CHECKOUT],
    filter_compare: 'equals',
    filter_key: 'product_id',
    action: actionDefault,
  },
  {
    title: i18n.$t('automations.models.installment_activate.title'),
    desc: i18n.$t('automations.models.installment_activate.desc'),
    category: 'recurrency',
    trigger: 'installment_activate',
    checkout: [Features.SPARK_CHECKOUT],
    filter_compare: 'equals',
    filter_key: 'product_id',
    action: actionDefault,
  },
  {
    title: i18n.$t('automations.models.installment_cancel.title'),
    desc: i18n.$t('automations.models.installment_cancel.desc'),
    category: 'recurrency',
    trigger: 'installment_cancel',
    checkout: [Features.SPARK_CHECKOUT],
    filter_compare: 'equals',
    filter_key: 'product_id',
    action: actionDefault,
  },
  {
    title: i18n.$t('automations.models.installment_recover.title'),
    desc: i18n.$t('automations.models.installment_recover.desc'),
    category: 'recurrency',
    trigger: 'installment_recover',
    checkout: [Features.SPARK_CHECKOUT],
    filter_compare: 'equals',
    filter_key: 'product_id',
    action: actionDefault,
  },
  {
    title: i18n.$t('automations.models.installment_update.title'),
    desc: i18n.$t('automations.models.installment_update.desc'),
    category: 'recurrency',
    trigger: 'installment_update',
    checkout: [Features.SPARK_CHECKOUT],
    filter_compare: 'equals',
    filter_key: 'product_id',
    action: actionDefault,
  },
  {
    title: i18n.$t('automations.models.installment_mark_past_due.title'),
    desc: i18n.$t('automations.models.installment_mark_past_due.desc'),
    category: 'recurrency',
    trigger: 'installment_mark_past_due',
    checkout: [Features.SPARK_CHECKOUT],
    filter_compare: 'equals',
    filter_key: 'product_id',
    action: actionDefault,
  },
  {
    title: i18n.$t('automations.models.installment_end.title'),
    desc: i18n.$t('automations.models.installment_end.desc'),
    category: 'recurrency',
    trigger: 'installment_end',
    checkout: [Features.SPARK_CHECKOUT],
    filter_compare: 'equals',
    filter_key: 'product_id',
    action: actionDefault,
  },
  {
    title: i18n.$t('automations.models.smart_installment_deactivate.title'),
    desc: i18n.$t('automations.models.smart_installment_deactivate.desc'),
    category: 'recurrency',
    trigger: 'smart_installment_deactivate',
    checkout: [Features.SPARK_CHECKOUT],
    filter_compare: 'equals',
    filter_key: 'product_id',
    action: actionDefault,
  },
  {
    title: i18n.$t('automations.models.smart_installment_activate.title'),
    desc: i18n.$t('automations.models.smart_installment_activate.desc'),
    category: 'recurrency',
    trigger: 'smart_installment_activate',
    checkout: [Features.SPARK_CHECKOUT],
    filter_compare: 'equals',
    filter_key: 'product_id',
    action: actionDefault,
  },
  {
    title: i18n.$t('automations.models.smart_installment_cancel.title'),
    desc: i18n.$t('automations.models.smart_installment_cancel.desc'),
    category: 'recurrency',
    trigger: 'smart_installment_cancel',
    checkout: [Features.SPARK_CHECKOUT],
    filter_compare: 'equals',
    filter_key: 'product_id',
    action: actionDefault,
  },
  {
    title: i18n.$t('automations.models.smart_installment_recover.title'),
    desc: i18n.$t('automations.models.smart_installment_recover.desc'),
    category: 'recurrency',
    trigger: 'smart_installment_recover',
    checkout: [Features.SPARK_CHECKOUT],
    filter_compare: 'equals',
    filter_key: 'product_id',
    action: actionDefault,
  },
  {
    title: i18n.$t('automations.models.smart_installment_update.title'),
    desc: i18n.$t('automations.models.smart_installment_update.desc'),
    category: 'recurrency',
    trigger: 'smart_installment_update',
    checkout: [Features.SPARK_CHECKOUT],
    filter_compare: 'equals',
    filter_key: 'product_id',
    action: actionDefault,
  },
  {
    title: i18n.$t('automations.models.smart_installment_mark_past_due.title'),
    desc: i18n.$t('automations.models.smart_installment_mark_past_due.desc'),
    category: 'recurrency',
    trigger: 'smart_installment_mark_past_due',
    checkout: [Features.SPARK_CHECKOUT],
    filter_compare: 'equals',
    filter_key: 'product_id',
    action: actionDefault,
  },

  {
    title: i18n.$t('automations.models.smart_installment_end.title'),
    desc: i18n.$t('automations.models.smart_installment_end.desc'),
    category: 'recurrency',
    trigger: 'smart_installment_end',
    checkout: [Features.SPARK_CHECKOUT],
    filter_compare: 'equals',
    filter_key: 'product_id',
    action: actionDefault,
  },
  {
    title: i18n.$t('automations.models.25%-progress-on-a-course.title'),
    desc: i18n.$t('automations.models.25%-progress-on-a-course.desc'),
    category: 'interact_with_leads',
    trigger: 'progress_in_25',
    checkout: [Features.SPARK_CHECKOUT, Features.EXPRESS_CHECKOUT],
    filter_compare: 'equals',
    filter_key: 'school_product_id',
    action: actionDefault,
  },
  {
    title: i18n.$t('automations.models.50%-progress-on-a-course.title'),
    desc: i18n.$t('automations.models.50%-progress-on-a-course.desc'),
    category: 'interact_with_leads',
    trigger: 'progress_in_50',
    checkout: [Features.SPARK_CHECKOUT, Features.EXPRESS_CHECKOUT],
    filter_compare: 'equals',
    filter_key: 'school_product_id',
    action: actionDefault,
  },
  {
    title: i18n.$t('automations.models.75%-progress-on-a-course.title'),
    desc: i18n.$t('automations.models.75%-progress-on-a-course.desc'),
    category: 'interact_with_leads',
    trigger: 'progress_in_75',
    checkout: [Features.SPARK_CHECKOUT, Features.EXPRESS_CHECKOUT],
    filter_compare: 'equals',
    filter_key: 'school_product_id',
    action: actionDefault,
  },
  {
    title: i18n.$t('automations.models.100%-progress-on-a-course.title'),
    desc: i18n.$t('automations.models.100%-progress-on-a-course.desc'),
    category: 'interact_with_leads',
    trigger: 'progress_in_100',
    checkout: [Features.SPARK_CHECKOUT, Features.EXPRESS_CHECKOUT],
    filter_compare: 'equals',
    filter_key: 'school_product_id',
    action: actionDefault,
  },
  {
    title: i18n.$t('automations.models.issuance-of-certificate.title'),
    desc: i18n.$t('automations.models.issuance-of-certificate.desc'),
    category: 'interact_with_leads',
    trigger: 'received_certificate',
    checkout: [Features.SPARK_CHECKOUT, Features.EXPRESS_CHECKOUT],
    filter_compare: 'include',
    filter_key: 'products_ids',
    action: actionDefault,
  },
  {
    title: i18n.$t('automations.models.funnel-lead-captured.title'),
    desc: i18n.$t('automations.models.funnel-lead-captured.desc'),
    category: 'interact_with_leads',
    trigger: 'funnel_lead_captured',
    checkout: [],
    filter_compare: 'equals',
    filter_key: 'product_id',
    action: actionDefault,
  },
  {
    title: i18n.$t('automations.models.subscription-canceled-by-customer.title'),
    desc: i18n.$t('automations.models.subscription-canceled-by-customer.desc'),
    category: 'cart_recovery',
    trigger: 'subscription_canceled_buyer',
    checkout: [Features.SPARK_CHECKOUT],
    filter_compare: 'equals',
    filter_key: 'product_id',
    action: actionDefault,
  },
  {
    title: i18n.$t('automations.models.credit-card-declined.title'),
    desc: i18n.$t('automations.models.credit-card-declined.desc'),
    category: 'cart_recovery',
    trigger: 'payment_credit_cart_refused',
    checkout: [Features.SPARK_CHECKOUT],
    filter_compare: 'equals',
    filter_key: 'product_id',
    action: actionDefault,
  },
  {
    title: i18n.$t('automations.models.payment_overdue.title'),
    desc: i18n.$t('automations.models.payment_overdue.desc'),
    category: 'cart_recovery',
    trigger: 'payment_overdue',
    checkout: [Features.SPARK_CHECKOUT],
    filter_compare: 'equals',
    filter_key: 'product_id',
    action: actionDefault,
  },
  {
    title: i18n.$t('automations.models.subscription-canceled-by-producer.title'),
    desc: i18n.$t('automations.models.subscription-canceled-by-producer.desc'),
    category: 'cart_recovery',
    trigger: 'subscription_canceled_producer',
    checkout: [Features.SPARK_CHECKOUT],
    filter_compare: 'equals',
    filter_key: 'product_id',
    action: actionDefault,
  },
  {
    title: i18n.$t('automations.models.payment-refused.title'),
    desc: i18n.$t('automations.models.payment-refused.desc'),
    category: 'cart_recovery',
    trigger: 'canceled_order',
    checkout: [Features.EXPRESS_CHECKOUT],
    filter_compare: 'include',
    filter_key: 'products_ids',
    action: actionDefault,
  },
  {
    title: i18n.$t('automations.models.subscription-cancellation.title'),
    desc: i18n.$t('automations.models.subscription-cancellation.desc'),
    category: 'cart_recovery',
    trigger: 'subscription_canceled',
    checkout: [Features.EXPRESS_CHECKOUT],
    filter_compare: 'include',
    filter_key: 'products_ids',
    action: actionDefault,
  },
  {
    title: i18n.$t('automations.models.abandoned_cart.title'),
    desc: i18n.$t('automations.models.abandoned_cart.desc'),
    category: 'new_cart_recovery',
    trigger: 'abandoned_cart',
    checkout: [Features.SPARK_CHECKOUT],
    filter_compare: 'include',
    filter_key: 'products_ids',
    action: actionDefault,
  },
];
