import axiosXavier from './axios';
import GeneralHelper from '@/shared/helpers/general';
import { Automation } from '@/types/automation';

export default {
  getEvents(params: any[]) {
    return axiosXavier.get(`events?${GeneralHelper.parseToQueryString(params)}`).then(({ data }) => data);
  },

  getActions(params: any[]) {
    return axiosXavier.get(`actions?${GeneralHelper.parseToQueryString(params)}`).then(({ data }) => data);
  },

  getAction(automationId: string): Promise<Automation> {
    return axiosXavier.get(`actions/${automationId}`).then(({ data }) => data);
  },

  createAction(trigger_name: string, action: any): Promise<Automation> {
    return axiosXavier.post('actions', { trigger_name, hermes_action: action }).then(({ data }) => data);
  },

  updateAction(action: any) {
    return axiosXavier.put(`actions/${action.id}`, { hermes_action: action });
  },

  deleteAction(action_id: any) {
    return axiosXavier.delete(`actions/${action_id}`);
  },

  testAutomation(params: any) {
    return axiosXavier.post('actions_simulator', params);
  },
};
