export const defaultBody = `{
    "execution_at": "{{executionAt}}",
    "platform_uid": "{{platformUid}}",
    "recurrency": {
        "id": "{{recurrencyId}}",
        "status": "{{recurrencyStatus}}",
        "canceled_by": "{{recurrencyCanceledBy}}",
        "amount": "{{recurrencyAmount}}",
        "period": "{{recurrencyPeriod}}",
        "next_invoice_at": "{{recurrencyNextInvoiceAt}}",
        "created_at": "{{recurrencyCreatedAt}}",
        "total_collected": "{{recurrencyTotalCollected}}",
        "expiration_at": "{{recurrencyExpirationAt}}",
        "expired": "{{recurrencyExpired}}",
        "max_cycles": "{{recurrencyMaxCycles}}",
        "cycle_restriction": "{{recurrencyCycleRestriction}}",
        "paid_installments": "{{recurrencyPaidInstallments}}",
        "allow_overdue_access": "{{recurrencyAllowOverdueAccess}}",
        "type": "{{recurrencyType}}",
        "cancellation_reason": "{{recurrencyCancellationReason}}",
        "canceled_at": "{{recurrencyCanceledAt}}",
        "overdue_count": "{{recurrencyOverdueCount}}"
    },
    "purchase": {
        "id": "{{purchaseId}}",
        "bump_used": "{{purchaseBumpUsed}}",
        "is_upsell": "{{purchaseIsUpsell}}"
    },
    "buyer": {
        "id": "{{buyerId}}",
        "email": "{{buyerEmail}}",
        "student_uid": "{{buyerStudentUid}}",
        "address_number": "{{buyerAddressNumber}}",
        "street_address": "{{buyerStreetAddress}}",
        "city": "{{buyerCity}}",
        "complement": "{{buyerComplement}}",
        "ddi": "{{buyerDDI}}",
        "district": "{{buyerDistrict}}",
        "document_type": "{{buyerDocumentType}}",
        "full_name": "{{buyerFullName}}",
        "phone": "{{buyerPhone}}",
        "phone_raw": "{{buyerPhoneRaw}}",
        "state": "{{buyerState}}",
        "zip_code": "{{buyerZipCode}}",
        "created_at": "{{buyerCreatedAt}}",
        "document_number": "{{buyerDocumentNumber}}"
    },
    "offer": {
        "id": "{{offerId}}",
        "title": "{{offerTitle}}",
        "payment_methods": "{{offerPaymentMethods | to_json}}",
        "price": "{{offerPrice}}",
        "period": "{{offerPeriod}}",
        "offer_discount": "{{offerWithDiscount}}"
    },
    "cart": {
        "id": "{{cartId}}",
        "src": "{{cartSrc}}",
        "multicard": "{{cartMulticard}}",
        "kind": "{{cartKind}}",
        "public_id": "{{cartPublicId}}",
        "invitation_url": "{{invitationUrl}}"
    },
    "product": {
        "id": "{{productId}}",
        "name": "{{productName}}",
        "product_uid": "{{productUid}}",
        "is_principal": "{{productIsPrincipal}}"
    },
    "coupon": {
        "id": "{{couponId}}",
        "available_until": "{{couponAvailableUntil}}",
        "code": "{{couponCode}}",
        "consumed": "{{couponConsumed}}",
        "fixed_amount": "{{couponFixedAmount}}",
        "percentage_value": "{{couponPercentageValue}}",
        "quantity": "{{couponQuantity}}",
        "created_at": "{{couponCreatedAt}}",
        "product_id": "{{couponProductId}}"
    },
    "bumps": "{{bumps | to_json}}",
    "payments": "{{allPayments | to_json}}"
}`;

export const recurrencyBody = `{
    "execution_at": "{{executionAt}}",
    "platform_uid": "{{platformUid}}",
    "recurrency": {
        "id": "{{recurrencyId}}",
        "status": "{{recurrencyStatus}}",
        "canceled_by": "{{recurrencyCanceledBy}}",
        "amount": "{{recurrencyAmount}}",
        "period": "{{recurrencyPeriod}}",
        "next_invoice_at": "{{recurrencyNextInvoiceAt}}",
        "created_at": "{{recurrencyCreatedAt}}",
        "total_collected": "{{recurrencyTotalCollected}}",
        "expiration_at": "{{recurrencyExpirationAt}}",
        "expired": "{{recurrencyExpired}}",
        "max_cycles": "{{recurrencyMaxCycles}}",
        "cycle_restriction": "{{recurrencyCycleRestriction}}",
        "paid_installments": "{{recurrencyPaidInstallments}}",
        "allow_overdue_access": "{{recurrencyAllowOverdueAccess}}",
        "type": "{{recurrencyType}}",
        "cancellation_reason": "{{recurrencyCancellationReason}}",
        "canceled_at": "{{recurrencyCanceledAt}}",
        "overdue_count": "{{recurrencyOverdueCount}}"
    },
    "purchase": {
        "id": "{{purchaseId}}",
        "bump_used": "{{purchaseBumpUsed}}",
        "is_upsell": "{{purchaseIsUpsell}}"
    },
    "buyer": {
        "id": "{{buyerId}}",
        "email": "{{buyerEmail}}",
        "student_uid": "{{buyerStudentUid}}",
        "address_number": "{{buyerAddressNumber}}",
        "street_address": "{{buyerStreetAddress}}",
        "city": "{{buyerCity}}",
        "complement": "{{buyerComplement}}",
        "ddi": "{{buyerDDI}}",
        "district": "{{buyerDistrict}}",
        "document_type": "{{buyerDocumentType}}",
        "full_name": "{{buyerFullName}}",
        "phone": "{{buyerPhone}}",
        "phone_raw": "{{buyerPhoneRaw}}",
        "state": "{{buyerState}}",
        "zip_code": "{{buyerZipCode}}",
        "created_at": "{{buyerCreatedAt}}",
        "document_number": "{{buyerDocumentNumber}}"
    },
    "offer": {
        "id": "{{offerId}}",
        "title": "{{offerTitle}}",
        "payment_methods": "{{offerPaymentMethods | to_json}}",
        "price": "{{offerPrice}}",
        "period": "{{offerPeriod}}",
        "offer_discount": "{{offerWithDiscount}}"
    },
    "cart": {
        "id": "{{cartId}}",
        "src": "{{cartSrc}}",
        "multicard": "{{cartMulticard}}",
        "kind": "{{cartKind}}",
        "public_id": "{{cartPublicId}}",
        "invitation_url": "{{invitationUrl}}"
    },
    "product": {
        "id": "{{productId}}",
        "name": "{{productName}}",
        "product_uid": "{{productUid}}",
        "is_principal": "{{productIsPrincipal}}"
    },
    "payments": "{{allPayments | to_json}}"
}`;

export const abandonedCartBody = `{
    "cart": {
        "id": "{{cartId}}",
        "src": "{{cartSrc}}",
        "kind": "{{cartKind}}",
        "public_id": "{{cartPublicId}}",
        "multicard": "{{cartMulticard}}",
        "cartInteractedAt": "{{cartInteractedAt}}"
    },
    "offer": {
        "id": "{{offerId}}",
        "price": "{{offerPrice}}",
        "title": "{{offerTitle}}",
        "period": "{{offerPeriod}}",
        "payment_methods": "{{offerPaymentMethods | to_json}}",
        "offerLink": "{{offerLink}}"
    },
    "product": {
        "id": "{{productUid}}",
        "name": "{{productName}}",
        "product_uid": "{{productUid}}",
        "is_principal": "{{productIsPrincipal}}"
    },
    "buyer": {
        "email": "{{buyerEmail}}",
        "phone": "{{buyerPhone}}",
        "phone_raw": "{{buyerPhoneRaw}}",
        "full_name": "{{buyerFullName}}"
    },
    "bumps": "{{bumps | to_json}}",
    "checkout_form": {
        "filled_credit_card": "{{formFilledCreditCard}}",
        "filled_address_city": "{{formFilledAddressCity}}",
        "filled_address_state": "{{formFilledAddressState}}",
        "filled_address_number": "{{formFilledAddressNumber}}",
        "filled_address_street": "{{formFilledAddressStreet}}",
        "filled_address_zip_code": "{{formFilledAddressZipCode}}",
        "filled_address_district": "{{formFilledAddressDistrict}}"
    },
    "execution_at": "{{executionAt}}",
    "platform_uid": "{{platformUid}}"
}`;
