const user_variables = {
  'user.email': 'E-mail do usuário',
  'user.first_name': 'Primeiro nome do usuário',
  'user.last_name': 'Sobrenome do usuário',
  'user.phone': 'Celular do usuário',
};

const school_product_variables = {
  'school_product.id': 'ID do produto',
  'school_product.logo': 'Imagem do produto',
  'school_product.title': 'Titulo do produto',
};

const progress_variables = {
  ...school_product_variables,
  'progress_card.course_id': 'ID do curso',
  'progress_card.id': 'ID do card de progresso',
  'school_product.niche': 'Nicho do produto',
  'school_product.slug': 'Slug do produto',
};

const sparkcheckout_variables = {
  buyer_document_id: 'Número do documento',
  buyer_document_type: 'Tipo do documento',
  buyer_email: 'E-mail do cliente',
  buyer_name: 'Nome do cliente',
  buyer_phone: 'Celular do cliente',
  buyer_phone_ddi: 'DDI do celular do cliente',
  buyer_phone_raw: 'Celular do cliente (sem formatação)',
  buyer_city: 'Endereço: Cidade do comprador',
  buyer_complement: 'Endereço: Complemento',
  buyer_district: 'Endereço: Bairro',
  buyer_address_number: 'Endereço: Número',
  buyer_state: 'Endereço: Estado',
  buyer_address_street: 'Endereço: CEP',
  net_value_cents: 'Valor liquido (em centavos)',
  offer_discount: 'Valor do disconto',
  offer_id: 'O ID da oferta',
  offer_kind: 'O tipo da oferta',
  offer_price: 'O valor da oferta em centavos',
  'offer_price | money': 'O valor da oferta em reais',
  'offer_price | divided_by: 100.00': 'O valor da oferta em reais sem R$',
  offer_discount_value: 'O valor do desconto aplicado na oferta (caso seja utilizado um cupom)',
  offer_with_discount: 'O valor da oferta com desconto aplicado (caso seja utilizado um cupom)',
  offer_title: 'O nome da oferta',
  payment_value: 'Valor pago pelo comprador em centavos.',
  'payment_value | divided_by: 100.00': 'Valor pago pelo comprador em reais.',
  payment_date: 'A data do pagamento',
  payment_id: 'ID do pagamento',
  payment_method: 'O método de pagamento',
  payment_status: 'O Status do pagamento',
  product_id: 'O ID do produto',
  product_name: 'O Nome do produto',
  installments: 'O número de parcelas',
  installments_fees: 'Valor da taxa de juros do parcelamento em centavos',
  subscription_id: 'ID da assinatura do comprador (apenas para pagamentos relativos à assinaturas)',
  subscription_type: 'O tipo de recorrência',
  subscription_next_invoice_at: 'Data da próxima fatura da recorrência',
  subscription_expiration_at: 'Data de encerramento da recorrência',
  upsell: 'Indica se a venda é um upsell (true/false).',
  cart_src: 'SRC',
  pix_expiration_at: 'Data de expiração do PIX',
  boleto_expiration_at: 'Data de vencimento do Boleto',
  credit_card_refused_message: 'Motivo da recusa do cartão de crédito',
  subscription_canceled_by: 'Quem cancelou assinatura (produtor ou aluno)',
  subscription_status: 'O status da assinatura',
  subscription_available_until: 'Data de término do ciclo atual da assinatura',
  payment_created: 'A data de criação do pagamento',
  next_payment: 'A data do próximo pagamento',
  execution_at: 'Quando o webhook foi disparado',
  overdue_count: 'Contador de pagamentos que estão pendentes',
  paid_installments: 'Contador de pagamentos que estão aprovados/confirmados',
};

const new_sparkcheckout_variables_installments_only = {
  'bumps | to_json': 'Traz os dados de todos os orderbumps da compra',
  couponId: 'Identificador único do cupom',
  couponAvailableUntil: 'Data de validade do cupom',
  couponCode: 'Código do cupom',
  couponConsumed: 'Quantidade de cupons consumidos',
  couponPercentageValue: 'Valor percentual de desconto do cupom',
  couponQuantity: 'Quantidade total de cupons criados',
  couponCreatedAt: 'Data de criação do cupom',
  couponProductId: 'Identificador do produto associado ao cupom',
};

const new_sparkcheckout_variables_default = {
  recurrencyId: 'Identificador único da recorrência',
  recurrencyStatus: 'Status atual da recorrência',
  recurrencyCanceledBy: 'Identificação de quem cancelou a recorrência',
  recurrencyAmount: 'Valor total bruto do pagamento da recorrência',
  recurrencyPeriod: 'Período da recorrência',
  recurrencyNextInvoiceAt: 'Data da próxima fatura',
  recurrencyCreatedAt: 'Data de criação da recorrência',
  recurrencyTotalCollected: 'Total bruto coletado até o momento',
  recurrencyExpirationAt: 'Data de expiração da recorrência',
  recurrencyExpired: 'Indicador se a recorrência expirou (true/false)',
  recurrencyMaxCycles: 'Limite de frequência para a recorrência',
  recurrencyCycleRestriction: 'Tipo de frequência da recorrência (limited ou unlimited)',
  purchaseId: 'ID da compra',
  recurrencyPaidInstallments: 'Quantidade de parcelas pagas',
  recurrencyAllowOverdueAccess: 'Indicação se o acesso é permitido em caso de pagamento atrasado',
  recurrencyType: 'Tipo de recorrência',
  recurrencyCancellationReason: 'Motivo do cancelamento da recorrência',
  recurrencyCanceledAt: 'Data do cancelamento da recorrência',
  recurrencyOverdueCount: 'Contagem de pagamentos com status overdue ou refused',
  buyerId: 'Identificador único do comprador',
  buyerEmail: 'Endereço de email do comprador',
  buyerStudentUid: 'Identificador único universal do aluno',
  buyerAddressNumber: 'Número do endereço do comprador',
  buyerStreetAddress: 'Rua do endereço do comprador',
  buyerCity: 'Cidade do comprador',
  buyerComplement: 'Complemento do endereço',
  buyerDDI: 'Código DDI do telefone',
  buyerDistrict: 'Bairro do comprador',
  buyerDocumentType: 'Tipo de documento (CPF/CNPJ)',
  buyerFullName: 'Nome completo do comprador',
  buyerPhone: 'Número de telefone do comprador',
  buyerPhoneRaw: 'Número de telefone do comprador sem formatação',
  buyerState: 'Estado do comprador',
  buyerZipCode: 'Código postal do comprador',
  buyerCreatedAt: 'Data de criação do registro do comprador',
  buyerDocumentNumber: 'Identificador do documento do comprador',
  offerId: 'Identificador único e imutável da oferta',
  offerTitle: 'Título da oferta',
  offerPaymentMethods: 'Métodos de pagamento aceitos para a oferta',
  offerPrice: 'Preço da oferta',
  offerPeriod: 'Se a oferta é unitária ou assinatura',
  offerWithDiscount: 'Valor da oferta subtraído do desconto via cupom',
  cartId: 'Identificador único do carrinho',
  cartSrc: 'Fonte do carrinho',
  cartMulticard: 'Boolean para carrinho com múltiplos cartões',
  cartKind: 'Tipo de carrinho',
  cartPublicId: 'ID publico do carrinho',
  invitationUrl: 'URL de convite',
  productId: 'Identificador único do produto',
  productName: 'Nome do produto',
  productUid: 'Identificador único universal do produto',
  productIsPrincipal: 'Booleano que identifica se o produto é o principal no carrinho',
  executionAt: 'Data de execução do evento',
  platformUid: 'Identificador único universal da plataforma',
  purchaseBumpUsed: 'Indica se houve pagamento de produtos order bumps',
  purchaseIsUpsell: 'Indica se essa é uma compra de upsell',
  'allPayments | to_json': 'Traz os dados de todos os pagamentos da recorrencia',
};

const variables = {
  ended_lesson: {
    ...user_variables,
    'course.id': 'ID do curso',
    'course.image_url': 'Imgem do curso',
    'course.kind': 'Tipo do curso',
    'course.name': 'Nome do curso',
    'lesson.id': 'ID da aula',
    'lesson.title': 'Título da aula',
  },

  completed_activity: {
    ...user_variables,
    'exam_answer.activity_id': 'ID da atividade',
    'exam_answer.auto_corrected': 'Data da correção',
    'exam_answer.id': 'ID da resposta',
    'exam_answer.lesson_progress_id': 'ID do progresso',
  },

  progress_in_25: {
    ...user_variables,
    ...progress_variables,
  },

  progress_in_50: {
    ...user_variables,
    ...progress_variables,
  },

  progress_in_75: {
    ...user_variables,
    ...progress_variables,
  },

  progress_in_100: {
    ...user_variables,
    ...progress_variables,
  },

  received_certificate: {
    ...user_variables,
    ...school_product_variables,
    'certificate.course_id': 'ID do do curso',
    'certificate.enrollment_id': 'ID da matrícula',
    'certificate.id': 'ID do certificado',
    'certificate.metadata.course.duration': 'Carga horária do curso',
    'certificate.metadata.course.teacher': 'Nome do instrutor do curso',
    'certificate.metadata.course.title': 'Título do curso',
    'certificate.metadata.school.name': 'Nome da escola',
    'certificate.metadata.school.owner': 'Nome na assinatura do certificado',
    'certificate.metadata.student.cpf': 'CPF do comprador',
    'certificate.metadata.student.name': 'Nome do comprador',
    'certificate.metadata.student.rg': 'RG do comprador',
    'certificate.s3_file_url': 'URL do certificado em PDF',
  },

  new_order: {
    ...user_variables,
    'order.amount_to_pay': 'Total pago (em centavos)',
    'order.id': 'ID do pedido',
    'order.status': 'Status do pedido',
    'order.student_id': 'ID do aluno',
  },

  canceled_order: {
    ...user_variables,
    'order.amount_to_pay': 'Total pago (em centavos)',
    'order.id': 'ID do pedido',
    'order.status': 'Status do pedido',
    'order.student_id': 'ID do aluno',
  },

  payment_confirmed: {
    ...user_variables,
    'address.city': 'Cidade do comprador',
    'address.complement': 'Complemento do comprador',
    'address.district': 'Bairro do comprador',
    'address.number': 'Número do comprador',
    'address.state': 'Estado do comprador',
    'address.street': 'Logradouro do comprador',
    'address.zip_code': 'CEP do comprador',
    'payment.amount': 'Valor total em centavos',
    'payment.id': 'ID do pagamento',
    'payment.installments': 'Numero de parcelas',
    'payment.status': 'Status do pagamento',
    'payment.response.customer.document_number': 'Número do documento',
    'payment.response.customer.document_type': 'Tipo do documento',
    'payment.response.customer.email': 'E-mail do comprador',
    'payment.response.customer.id': 'ID do cliente',
    'payment.response.customer.name': 'Nome do cliente',
  },

  spark_payment_confirmed: {
    ...sparkcheckout_variables,
  },

  spark_payment_confirmed_order_bump: {
    ...sparkcheckout_variables,
  },

  payment_pix_created: {
    ...sparkcheckout_variables,
    pix_expiration_at: 'Data de expiração do pix',
  },

  payment_credit_cart_refused: {
    ...sparkcheckout_variables,
    credit_card_refused_message: 'Mensagem de cartão recusado',
  },

  spark_payment_boleto_created: {
    ...sparkcheckout_variables,
  },

  chargeback_request: {
    ...sparkcheckout_variables,
  },

  funnel_lead_captured: {
    funnel_id: 'ID do funil',
    funnel_template_name: 'Nome do template utilizado na captura do lead',
    lead_email: 'E-mail do lead',
    lead_from_import: 'Lead originado por importação?',
  },

  subscription_canceled_producer: {
    ...sparkcheckout_variables,
  },

  payment_overdue: {
    ...sparkcheckout_variables,
  },

  payment_boleto_overdue: {
    ...sparkcheckout_variables,
  },

  payment_pix_overdue: {
    ...sparkcheckout_variables,
  },

  subscription_canceled_buyer: {
    ...sparkcheckout_variables,
  },

  refunded: {
    ...sparkcheckout_variables,
  },

  subscription_deactivate: {
    ...new_sparkcheckout_variables_default,
  },

  subscription_activate: {
    ...new_sparkcheckout_variables_default,
  },

  subscription_cancel: {
    ...new_sparkcheckout_variables_default,
  },

  subscription_recover: {
    ...new_sparkcheckout_variables_default,
  },

  subscription_update: {
    ...new_sparkcheckout_variables_default,
  },

  subscription_mark_past_due: {
    ...new_sparkcheckout_variables_default,
  },

  subscription_end: {
    ...new_sparkcheckout_variables_default,
  },

  installment_deactivate: {
    ...new_sparkcheckout_variables_default,
    ...new_sparkcheckout_variables_installments_only,
  },

  installment_activate: {
    ...new_sparkcheckout_variables_default,
    ...new_sparkcheckout_variables_installments_only,
  },

  installment_cancel: {
    ...new_sparkcheckout_variables_default,
    ...new_sparkcheckout_variables_installments_only,
  },

  installment_recover: {
    ...new_sparkcheckout_variables_default,
    ...new_sparkcheckout_variables_installments_only,
  },

  installment_update: {
    ...new_sparkcheckout_variables_default,
    ...new_sparkcheckout_variables_installments_only,
  },

  installment_end: {
    ...new_sparkcheckout_variables_default,
    ...new_sparkcheckout_variables_installments_only,
  },

  installment_mark_past_due: {
    ...new_sparkcheckout_variables_default,
    ...new_sparkcheckout_variables_installments_only,
  },

  smart_installment_cancel: {
    ...new_sparkcheckout_variables_default,
    ...new_sparkcheckout_variables_installments_only,
  },

  smart_installment_activate: {
    ...new_sparkcheckout_variables_default,
    ...new_sparkcheckout_variables_installments_only,
  },

  smart_installment_deactivate: {
    ...new_sparkcheckout_variables_default,
    ...new_sparkcheckout_variables_installments_only,
  },

  smart_installment_recover: {
    ...new_sparkcheckout_variables_default,
    ...new_sparkcheckout_variables_installments_only,
  },

  smart_installment_update: {
    ...new_sparkcheckout_variables_default,
    ...new_sparkcheckout_variables_installments_only,
  },

  smart_installment_mark_past_due: {
    ...new_sparkcheckout_variables_default,
    ...new_sparkcheckout_variables_installments_only,
  },

  smart_installment_end: {
    ...new_sparkcheckout_variables_default,
    ...new_sparkcheckout_variables_installments_only,
  },

  abandoned_cart: {
    cartId: 'Identificador único do carrinho',
    cartSrc: 'Fonte do carrinho',
    cartKind: 'Tipo de carrinho',
    cartPublicId: 'ID publico do carrinho',
    cartMulticard: 'Boolean para carrinho com múltiplos cartões',
    cartInteractedAt: 'Data da ultima atualização do carrinho',
    offerId: 'Identificador único e imutável da oferta',
    offerPrice: 'Preço da oferta',
    offerTitle: 'Título da oferta',
    offerPeriod: 'Se a oferta é unitária ou assinatura',
    offerPaymentMethods: 'Métodos de pagamento aceitos para a oferta',
    offerLink: 'Link da oferta',
    productId: 'Identificador único do produto',
    productName: 'Nome do produto',
    productUid: 'Identificador único universal do produto',
    productIsPrincipal: 'Booleano que identifica se o produto é o principal no carrinho',
    buyerEmail: 'Endereço de e-mail do comprador',
    buyerPhone: 'Número de telefone do comprador',
    buyerPhoneRaw: 'Número de telefone do comprador sem formatação',
    buyerFullName: 'Nome completo do comprador',
    'bumps | to_json': 'Traz os dados de todos os orderbumps da compra',
    formFilledCreditCard: 'Booleano que identifica se o cartão foi preenchido',
    formFilledAddressCity: 'Booleano que identifica se a cidade foi preenchida',
    formFilledAddressState: 'Booleano que identifica se o estado foi preenchido',
    formFilledAddressNumber: 'Booleano que identifica se o numero do endereço foi preenchido',
    formFilledAddressStreet: 'Booleano que identifica se a rua foi preenchida',
    formFilledAddressZipCode: 'Booleano que identifica se o CEP foi preenchido',
    formFilledAddressDistrict: 'Booleano que identifica se o bairro preenchido',
    executionAt: 'Data de execução do evento',
    platformUid: 'Identificador único universal da plataforma',
  },
};

export type TriggerType = keyof typeof variables;

export default variables;
