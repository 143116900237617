










































import debug from 'debug';
import { Component, Prop, Vue } from 'vue-property-decorator';
import productService from '@/sparkmembers/services/product';

import debounce from 'lodash.debounce';
import _ from 'lodash';
import { Automation } from '@/types/automation';
import { Pagination } from '@/types/pagination';
import HsLoading from '@/components/Loading.vue';
import { AutomationModels, progressAutomationsTriggers } from '@/data/automation_models';

const logging = debug('hs:herospark_automations_filters');

@Component({
  components: {
    HsLoading,
    MultiselectFilters: () => import('./MultiselectFilters.vue'),
  },
})
export default class AutomationsEditFormFilters extends Vue {
  @Prop({ required: true, default: {} }) automation!: Automation;
  @Prop({ required: true, default: [] }) selecteds!: any[];

  hasFilter: boolean = false;
  options: any = [];

  products: any[] = [];
  search: string = '';

  loading: boolean = true;

  pagination: Pagination = {
    current_page: 1,
    per_page: 20,
    total_pages: 0,
    total_count: 0,
  };

  filtersModel: null | any[] = null;

  get filterPropsByModels() {
    return (
      AutomationModels.find(({ trigger }) => trigger === this.automation.event.trigger) || {
        filter_compare: '',
        filter_key: '',
      }
    );
  }

  async created() {
    this.options = [
      { text: this.$t('automations.edit.form.filters.options.all-products'), value: false },
      { text: this.$t('automations.edit.form.filters.options.specific-products'), value: true },
    ];

    await this.initFilters();
    this.searchProducts = debounce(this.searchProducts, 300);
  }

  async initFilters() {
    if (this.selecteds.length) {
      this.hasFilter = true;
      await this.getProducts();
    }
  }

  async onChangeFilterType(value: boolean) {
    this.filtersModel = null;

    this.$emit('input', {});

    if (value && !this.products.length) await this.getProducts();
  }

  getComponentData(form) {
    this.filtersModel = form;
    const { filter_compare, filter_key } = this.filterPropsByModels;

    const filters = form
      ? {
          filters: form.map(filter => ({
            compare: filter_compare,
            value: filter.id,
            key: filter_key,
          })),
          operator: 'or',
        }
      : {};

    this.$emit('input', filters);
  }

  searchProducts(search: string) {
    this.loading = true;
    this.products = [];
    this.pagination.current_page = 1;
    this.getProducts(search);
  }

  async getProducts(searchByTitle: string = '') {
    try {
      const query = [
        { key: 'page', value: this.pagination.current_page },
        { key: 'per_page', value: this.pagination.per_page },
        { key: 'sort', value: 'title' },
        { key: 'request_attributes', value: 'library_resource' },
      ];

      searchByTitle !== '' && query.push({ key: 'title', value: `${searchByTitle}` });

      const { school_products, total_pages, total_count } = await productService.getAll(query);

      this.pagination.total_pages = total_pages;
      this.pagination.total_count = total_count;

      const filtered_products = progressAutomationsTriggers.includes(this.automation.event.trigger)
        ? school_products.filter(product => product.library_resource.resource.type != 'path')
        : school_products;

      this.products = _.unionBy(this.products, filtered_products, 'id');
    } catch (error) {
      logging('error on get products', error);
    } finally {
      this.loading = false;
    }
  }

  async paginate($state: any) {
    if (this.pagination.current_page < this.pagination.total_pages) {
      this.pagination.current_page += 1;
      await this.getProducts(this.search);
      $state.loaded();
    } else {
      $state.complete();
    }
  }
}
