
















































































































































































































































import { Component, Mixins } from 'vue-property-decorator';
import { required, minLength } from 'vuelidate/lib/validators';
import { TranslateResult } from 'vue-i18n';

import productService from '@/sparkmembers/services/product';
import eventService from '@/services/event';

import { AutomationsTabs } from '@/libs/automation';
import { Automation, AutomationActionType, AutomationScopeType } from '@/types/automation';
import ToastHelper from '@/shared/helpers/toast';

import debug from 'debug';
import _ from 'lodash';

import PageHeader from '@/components/_structures/PageHeader.vue';
import HsLoading from '@/components/Loading.vue';
import HsEditableText from '@/components/EditableText.vue';
import MButton from '@/shared/components/MButton.vue';
import PageLayout from '@/layout/PageLayout.vue';
import Confirm from '@/shared/mixins/Confirm';
import HsForm from '@/components/Form.vue';
import MSwitch from '@/shared/components/MSwitch.vue';
import AutomationsEditFormSendgrid from './components/AutomationsEditFormSendgrid.vue';
import AutomationsEditFormWebhook from './components/AutomationsEditFormWebhook.vue';
import AutomationsEditFormFilters from './components/AutomationsEditFormFilters.vue';
import { AutomationModels } from '@/data/automation_models';
import TrackingHelper from '@/shared/helpers/tracking';
import { Watch } from 'vue-property-decorator';

enum Modal {
  KNOW_HOW_MODAL = 'know-how-modal',
  MODEL_MODAL = 'added-by-model-modal',
  INCOMPLETE_EDITION_MODAL = 'incomplete-edition-modal',
}

const logging = debug('hs:herospark_automations');

@Component({
  components: {
    PageLayout,
    PageHeader,
    HsForm,
    HsLoading,
    MButton,
    MSwitch,
    HsEditableText,
    AutomationsEditModalKnowHow: () => import('./components/AutomationsEditModalKnowHow.vue'),
    AutomationsEditModalAddedByModel: () => import('./components/AutomationsEditModalAddedByModel.vue'),
    ConfirmModal: () => import('@/components/ConfirmModal.vue'),
    AutomationsModalIncompleteEdition: () => import('../../components/AutomationsModalIncompleteEdition.vue'),
    AutomationsEditFormSendgrid,
    AutomationsEditFormWebhook,
    AutomationsEditFormFilters,
  },
})
export default class AutomationsEdit extends Mixins(Confirm) {
  automation: Automation = {} as Automation;

  minAutomationNameLength: number = 4;
  tipsModel: number = 0;
  isLoading: boolean = true;
  isUpdatingName: boolean = false;
  isUpdating: boolean = false;
  isDeleting: boolean = false;
  showConfirmBox!: any;
  openedModal: Modal | null = null;
  modal: any = Modal;
  formInvalid: boolean = true;
  selectedFilters: any[] = [];
  hasHeader: boolean = false;
  $router: any;

  async created() {
    await this.getAutomation();
  }

  get automationId(): string {
    return this.$route.params.automationId;
  }

  get tipsList(): TranslateResult {
    return this.$t(`automations.edit.box-tips.${this.automation.integration}.tips`);
  }

  get formActivated(): string {
    if (this.automation.integration === 'HeroWebhook') return 'AutomationsEditFormWebhook';
    return `AutomationsEditForm${this.automation.integration}`;
  }

  get cantActive(): boolean {
    return this.isUpdating || this.$v.automation.$invalid || this.formInvalid;
  }

  get emptyConditions() {
    return _.isEmpty(this.automation.conditions);
  }

  get filtrable() {
    const automationModel =
      AutomationModels.find(automation => automation.trigger === this.automation.event.trigger) || {};
    return _.has(automationModel, 'filter_compare') && _.has(automationModel, 'filter_key');
  }

  mounted() {
    window.scrollTo(0, 0);
    this.openModalAddedByModel();
    this.hasHeader = this.$router?.history?.current?.meta?.hasHeader;
  }

  @Watch('automation.integration')
  onIntegrationChange() {
    this.$forceUpdate();
  }

  validations() {
    return {
      automation: {
        name: {
          required,
          minLength: minLength(4),
        },
        conditions: {
          required: () => !(!this.emptyConditions && !this.automation.conditions.filters.length),
        },
      },
    };
  }

  get isWebhook() {
    return ['Webhook', 'HeroWebhook'].includes(this.automation.integration);
  }

  getSettingsData({ form, invalid }) {
    this.automation = {
      ...this.automation,
      [this.isWebhook ? 'configurations' : 'settings']: form,
    };

    this.formInvalid = invalid;
  }

  async getAutomation() {
    try {
      const res = await eventService.getAction(this.automationId);
      this.automation = {
        ...res,
        name: res.name || this.$t(`automations.edit.automation-name`),
        category: this.$t(`automations.triggers.${res.event.trigger}.category`),
      };

      await this.getInitAppliedFilters();
    } catch (error) {
      this.goToAutomationsList();
      ToastHelper.dangerMessage(this.$t('automations.toast.error.get-automation'));
    } finally {
      this.isLoading = false;
    }
  }

  async getInitAppliedFilters(page = 1) {
    try {
      const { filters } = this.automation.conditions;

      if (!filters) return;

      const product_ids = filters.map(({ value }) => value);
      const query = [
        { key: 'page', value: page },
        { key: 'ids', value: product_ids },
      ];

      const { school_products, current_page, total_pages } = await productService.getAll(query);

      this.selectedFilters = _.unionBy(this.selectedFilters, school_products, 'id');

      if (current_page < total_pages) {
        await this.getInitAppliedFilters(current_page + 1);
      }
    } catch (error) {
      logging('error on get init applied filters', this.automation);
    }
  }

  webHookBody() {
    const params = _.pick(this.automation, [
      'id',
      'hermes_event_id',
      'integration',
      'active',
      'configurations',
      'conditions',
      'scope',
      'name',
      'category',
    ]);

    let body = params.configurations?.body;

    try {
      body = JSON.parse(params.configurations?.body || '');
    } catch (error) {
      logging('error parsing webhook body', error);
    }

    return { ...params, configurations: { ...params.configurations, body } };
  }

  async updateAutomation() {
    try {
      if (this.$v.automation.$invalid) return;
      this.isUpdating = true;

      let params = this.automation;

      if (this.isWebhook) {
        params = this.webHookBody() as Automation;
      }

      await eventService.updateAction(params);

      if (this.automation.active) {
        TrackingHelper.trackAutomationActive('edit_automation', this.automation.id);
      }
      this.$root.$emit('updated-automation');
      TrackingHelper.trackAutomationEdit(
        this.automation.event.trigger,
        this.automation.configurations?.request_method,
        this.automation.id
      );
      ToastHelper.successMessage(this.$t('automations.toast.success.automation-edited'));
    } catch (error) {
      ToastHelper.dangerMessage(this.$t('automations.toast.error.automation-edited'));
    } finally {
      this.isUpdating = false;
    }
  }

  openModalKnowHow() {
    this.openedModal = Modal.KNOW_HOW_MODAL;
    this.$bvModal.show(Modal.KNOW_HOW_MODAL);
  }

  openModalIncompleteEdition() {
    this.openedModal = Modal.INCOMPLETE_EDITION_MODAL;
    this.$bvModal.show(Modal.INCOMPLETE_EDITION_MODAL);
  }

  openModalAddedByModel() {
    if (this.$route.query.new) {
      this.openedModal = Modal.MODEL_MODAL;
      this.$bvModal.show(Modal.MODEL_MODAL);
    }
  }

  async closeModalAddedByModel(action: string) {
    this.openedModal = null;
    await this.updateAutomationByAction(action);
    ToastHelper.successMessage(this.$t('automations.toast.success.model-modal'));
  }

  async updateAutomationByAction(action: string) {
    try {
      this.automation.integration = action;

      if (this.isWebhook) {
        this.automation.scope = AutomationScopeType.INTEGRATION;
      }

      await eventService.updateAction({ ...this.automation, settings: {} });
    } catch (error) {
      logging('error on update automation by action', this.automation);
    }
  }

  async deleteAutomation() {
    try {
      const confirmOptions = {
        contentTitle: this.$t('automations.list.delete-confirm-modal.title'),
        contentDescription: this.$t('automations.list.delete-confirm-modal.subtitle'),
        okTitle: this.$t('automations.list.delete-confirm-modal.btn-ok'),
        cancelTitle: this.$t('automations.list.delete-confirm-modal.btn-cancel'),
        modalClass: 'delete-tag',
        variant: 'cherry',
        icon: 'trash-alt',
      };
      const confirmResponse = await this.showConfirmBox(confirmOptions);
      if (!confirmResponse) return;

      this.isDeleting = true;

      await eventService.deleteAction(this.automationId);

      this.goToAutomationsList();

      ToastHelper.successMessage(this.$t('automations.toast.success.automation-deleted'));
      TrackingHelper.trackAutomationDelete(this.automation.event.trigger, this.automation.id);
    } catch (error) {
      ToastHelper.dangerMessage(this.$t('automations.toast.error.automation-deleted'));
    } finally {
      this.isDeleting = false;
    }
  }

  async cancelAutomationEdit() {
    const confirmOptions = {
      contentTitle: this.$t('automations.edit.cancel-confirm-modal.title'),
      contentDescription: this.$t('automations.edit.cancel-confirm-modal.desc'),
      okTitle: this.$t('automations.edit.cancel-confirm-modal.btn-ok'),
      cancelTitle: this.$t('automations.edit.cancel-confirm-modal.btn-cancel'),
      variant: 'primary',
      icon: 'exclamation-circle',
    };
    const confirmResponse = await this.showConfirmBox(confirmOptions);
    if (!confirmResponse) return;

    this.goToAutomationsList();
  }

  goToAutomationsList() {
    this.$router.push({ name: 'AutomationsDashboard', query: { tab: AutomationsTabs.AUTOMATIONS_LIST_TAB } });
  }
}
